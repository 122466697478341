<template>
  <f7-page class="register-page">
    <f7-navbar back-link="Back"> </f7-navbar>

    <meta name="robots" content="noindex" />

    <section class="register-container" v-if="show">
      <div class="container">
        <img src="@/assets/images/svg/signup.svg" :alt="$t.getTranslation('LBL_SIGN_UP')" class="sign-up-img" loading="lazy" />
        <div class="info">
          <h1>{{ $t.getTranslation('LBL_SIGN_UP') }}</h1>

          <p>{{ $t.getTranslation('LBL_SIGN_UP_HEADER') }}</p>

          <f7-list class="checklist" media-list no-hairlines>
            <f7-list-item checkbox :checked="registerInfo.IsLegalAge === 1" :value="1" :title="$t.getTranslation('LBL_LEGAL_AGE')" :subtitle="$t.getTranslation('LBL_LEGAL_AGE_SUB')" @change="onLegalAgeChange"> </f7-list-item>

            <!-- <f7-list-item checkbox :checked="registerInfo.IsTermsAgreed === 1" :value="1" :title="$t.getTranslation('LBL_TERMS_AND_CONDITIONS')" :subtitle="$t.getTranslation('LBL_TERMS_AND_CONDITIONS_SUB')" @change="onTermsChange">
              <template #inner>
                <f7-link :href="$configs.externalPageLinks.termsAndConditions" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_TERMS_AND_CONDITIONS") }}</f7-link>
              </template>
            </f7-list-item>
            <f7-list-item checkbox :checked="registerInfo.IsPrivacyAgreed === 1" :value="1" :title="$t.getTranslation('LBL_PRIVACY_POLICY')" :subtitle="$t.getTranslation('LBL_PRIVACY_POLICY_SUB')" @change="onPrivacyChange">
              <template #inner>
                <f7-link :href="$configs.externalPageLinks.privacyPolicy" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_PRIVACY_POLICY") }}</f7-link>
              </template>
            </f7-list-item>

            <f7-list-item checkbox :checked="registerInfo.IsOrderandShipping === 1" :value="1" :title="$t.getTranslation('LBL_ORDER_AND_SHIPPING')" :subtitle="$t.getTranslation('LBL_ORDER_AND_SHIPPING_SUB')" @change="onPrivacyChange">
              <template #inner>
                <f7-link :href="$configs.externalPageLinks.orderAndShipping" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_ORDER_AND_SHIPPING") }}</f7-link>
              </template>
            </f7-list-item>


            <f7-list-item checkbox :checked="registerInfo.IsReturn === 1" :value="1" :title="$t.getTranslation('LBL_RETURN_POLICY')" :subtitle="$t.getTranslation('LBL_RETURN_POLICY_SUB')" @change="onPrivacyChange">
              <template #inner>
                <f7-link :href="$configs.externalPageLinks.returnPolicy" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_RETURN_POLICY") }}</f7-link>
              </template>
            </f7-list-item> -->

            <f7-list-item checkbox :checked="registerInfo.IsPolicy === 1" :value="1" :title="$t.getTranslation('LBL_POLICY')" :subtitle="$t.getTranslation('LBL_POLICY_SUB')" @change="onPolicyChange">
              <template #inner>
                <f7-link href="/profile/userguide/" target="_blank" external class="no-ripple">{{ $t.getTranslation('LBL_POLICY') }}</f7-link>
              </template>
            </f7-list-item>

            <f7-list-item
              checkbox
              :checked="registerInfo.IsNewsletterSubscribed === 1"
              :value="1"
              :title="$t.getTranslation('LBL_NEWSLETTER_PROMOTIONS')"
              :subtitle="$t.getTranslation('LBL_NEWSLETTER_PROMOTIONS_SUB')"
              @change="onNewsletterChange"
            >
            </f7-list-item>
          </f7-list>

          <f7-button fill large @click="next">{{ $t.getTranslation('LBL_START') }}</f7-button>
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, computed, inject, onMounted, ref } from 'vue'
import { useStore } from '@/store'
import { helpers } from '@/utils/helpers.js'
import { get } from '@/utils/axios'
import { configs } from '@/utils/configs.js'

export default defineComponent({
  name: 'RegisterPage',
  components: {},
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')

    const store = useStore()
    const show = ref(true)

    const registerInfo = computed(() => store.getters['register/getData'])

    store.dispatch('register/setData', {
      IsLegalAge: 0,
      IsNewsletterSubscribed: 0,
      IsPolicy: 0
    })

    onMounted(async () => {
      if (props?.f7route?.query?.group) {
        store.dispatch('register/setData', {
          GroupLinkCode: props.f7route.query.group
        })
      }

      if (props?.f7route?.query?.OpenId) {
        store.dispatch('register/setData', {
          OpenId: props.f7route.query.OpenId
        })
      }

      
      // else{
      //   //check if token is pass..
      //   if(props?.f7route?.query?.t){
      //     //GET: /mobile/register/check/register?Token=<TOKEN>
      //     let res = await get(
      //      `/mobile/register/check/register?Token=${props?.f7route?.query?.t}`
      //     );

      //     if(!res.status == 200){
      //       show.value = false;
      //       return props.f7router.navigate("/shop");
      //     }

      //   }else{
      //      show.value = false;
      //      return props.f7router.navigate("/shop");
      //   }
      // }
    })

    const onLegalAgeChange = (event) => {
      let value = event && event.target && event.target.checked ? 1 : 0
      store.dispatch('register/setData', {
        IsLegalAge: value
      })
    }

    const onNewsletterChange = (event) => {
      let value = event && event.target && event.target.checked ? 1 : 0

      store.dispatch('register/setData', {
        IsNewsletterSubscribed: value
      })
    }

    // const onTermsChange = (event) => {
    //   let value = event && event.target && event.target.checked ? 1 : 0;

    //   store.dispatch("register/setData", {
    //     IsTermsAgreed: value,
    //   });
    // };

    // const onPrivacyChange = (event) => {
    //   let value = event && event.target && event.target.checked ? 1 : 0;

    //   store.dispatch("register/setData", {
    //     IsPrivacyAgreed: value,
    //   });
    // };

    const onPolicyChange = (event) => {
      let value = event && event.target && event.target.checked ? 1 : 0

      store.dispatch('register/setData', {
        IsPolicy: value
      })
    }

    const isDisableNextButton = computed(() => {
      let registerInfo = store.getters['register/getData']
      return !registerInfo.IsTermsAgreed || !registerInfo.IsPrivacyAgreed
    })

    const next = () => {
      let registerInfo = store.getters['register/getData']

      if (!registerInfo.IsLegalAge) {
        helpers.createNotification({
          type: 'error',
          title: $t.getTranslation('LBL_ERROR'),
          message: $t.getTranslation('ERR_AGREE_LEGAL_AGE')
        })

        return false
      }
      if (!registerInfo.IsPolicy) {
        helpers.createNotification({
          type: 'error',
          title: $t.getTranslation('LBL_ERROR'),
          message: $t.getTranslation('ERR_AGREE_TERMS_AND_CONDITIONS')
        })

        return false
      }

      // if (!registerInfo.IsTermsAgreed) {
      //   helpers.createNotification({
      //     type: "error",
      //     title: $t.getTranslation("LBL_ERROR"),
      //     message: $t.getTranslation("ERR_AGREE_TERMS_AND_CONDITIONS"),
      //   });

      //   return false;
      // }

      // if (!registerInfo.IsPrivacyAgreed) {
      //   helpers.createNotification({
      //     type: "error",
      //     title: $t.getTranslation("LBL_ERROR"),
      //     message: $t.getTranslation("ERR_AGREE_PRIVACY_POLICY"),
      //   });

      //   return false;
      // }
      props.f7router.navigate('/register/mobileauthentication/')
    }

    return { registerInfo, onLegalAgeChange, onNewsletterChange, onPolicyChange, isDisableNextButton, next, show }
  }
})
</script>

<style>
.list .item-subtitle {
  white-space: normal;
}
.register-container .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px 80px;
  padding-bottom: 20px;
  width: calc(100% - 60px);
  margin: 10px auto;
  --f7-list-item-padding-horizontal: 0px;
  --f7-list-media-item-padding-horizontal: 0px;
  justify-content: center;
}
.register-container {
  height: 100%;
}
.sign-up-img {
  display: block;
}
</style>
